.footer__wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 137px;
    margin-left: 11vw;
    margin-right: 11vw;

    .footer__social {
            position: relative;
            bottom: 18px;
            font-family: Roboto;
            font-size: 18px;
        p {
            color: #FFFFFF;
        }
        a {
            color: #025CA6;
            margin-right: 1vw;
        }
    }
}

.footer__button {
    button {
        font-family: Roboto;
        font-size: 24px;
        padding-bottom: 21px;
        padding-top: 12px;
        padding-left: 25px;
        padding-right: 25px;
        border-radius: 10px;
        background: #FFFFFF;
        border: none;
        }

}


@media screen and (max-width: 600px) 
{
    .footer__button {
        display: none;
    }

}