.header {
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 57px;
}

.header__left {
    margin-left: 11vw;
}


#map_instruction {
    display: none;
}

.header__left--title {
    color: #ffffff;
    width: 35vw;
    font-family: "GetVoIP Grotesque", serif;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    text-transform: uppercase;
}


.header__right {
    margin-right: 11vw;
    display: flex;
    .header__right--discriptione {
        position: relative;
        top: 56px;
        width: 25vw;
        line-height: 180%;

        p {
            font-size: 18px;
            font-family: Roboto, sans-serif;
            color: #ffffff;
        }
    }
}

#num{
    font-weight: 700;
}

@media screen and (max-width: 600px) {
    .header__left--title {
        color: #ffffff;
        width: 75vw;
        font-family: "GetVoIP Grotesque", serif;
        font-style: normal;
        font-weight: 700;
        font-size: 1.5em;
        line-height: 38px;
        text-transform: uppercase;
    }
    #map_instruction {
        display: block;
        line-height: 130%;
        font-size: 12px;

    }
    .header__right {
        margin-right: 0px;
        display: flex;
        .header__right--discriptione {
            position: static;
            width: 86vw;
           padding-left: 30px;
    
            p {
                font-size: 18px;
                font-family: Roboto, sans-serif;
                color: #ffffff;
            }
        }
    }
    .header {
        display: flex;
        justify-content: space-between;
        position: relative;
        top: 30px;
        flex-direction: column;

    }
}