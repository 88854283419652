#carddep {
    visibility: hidden;
}
.region {
    margin-left: 400px;
    width: 200px;
    height: 200px;
    background-color: green;
    margin: 10px;
    float: left;
}

#cities {
    position: absolute;
    a {
        display: block;
    }
}


.depaty-list {
    flex-wrap: wrap;
    padding-top: 66px;
    width: 78vw;
    margin: auto;
    font-family: Roboto, serif;
    .cards {
        &__title {
            width: 45vw;
            padding-bottom: 12px;
            font-size: 38px;
        }
        &__description {
            font-size: 20px;
            width: 38vw;
            margin-bottom: 52px;
        }
    }
    &__title {
        color: #ffffff;
        font-size: 26px;
        line-height: 42px;
        width: 42vw;
        padding-bottom: 40px;
    }
    &__cards {
        box-sizing: border-box;
        display: flex;
        height: 340px;
        padding: 26px;
        background: #ffffff;
        border-radius: 18px;
    }
    &__photo {
        position: relative;
        bottom: 105px;
        left: 7vw;
    }
}

.cards__links--links {
        margin-bottom: 50px;
        display: flex;
    a {
        margin-right: 1vw;
    }
}

#second-list {
    margin-top: 35px;
}

#third-list {
    position: relative;
    top: 30px;
}

.third__wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 78vw;
    margin: auto;


    .third__wrapper--container {
        display: flex;
        justify-content: space-between;
        width: 36vw;
        background-color: #fff;
        height: 200px;
        border-radius: 18px;
        font-family: Roboto, serif;
        padding-top: 26px;
        margin-bottom: 25px;

        .third__wrapper--left {
            margin-left: 2vw;
            p {
                font-family: Roboto;
                font-size: 24px;
                span {
                    font-size: 46px;
                    line-height: 56px;
                }
            }
            
        }
        .third__wrapper--right {
            margin-right: 2vw;
        }
    }
    
}
.third-list__title {
    font-family: Roboto, serif;
    color: #ffffff;
    font-size: 26px;
    line-height: 42px;
    width: 42vw;
    padding-bottom: 40px;
    margin-left: 11vw;
    margin-top: 69px;
}


@media screen and (max-width: 600px) {
    .third-list__title {
        font-size: 1em;
        width: 90vw;
        line-height: 30px;

    }
    .depaty-list {
        padding-top: 66px;
        width: 90vw;
        margin: auto;
        font-family: Roboto, serif;
        .description {
            display: none;
        }
        .cards {
            overflow:hidden;

            &__title {
                width: 65vw;
                padding-bottom: 12px;
                font-size: 1.3em;
            }
            &__description {
                font-size: 0.8em;
                width: 50vw;
                margin-bottom: 0px;
            }
        }
        &__title {
            color: #ffffff;
            font-size: 1em;
            line-height: 42px;
            width: 90vw;
            padding-bottom: 40px;
        }
        &__cards {
            box-sizing: border-box;
            display: flex;
            height: 340px;
            padding: 10px;
            background: #ffffff;
            border-radius: 18px;
        }
        &__photo {
            position: relative;
            left: -100px;
             top: 150px;
         
        


        }
        img {
            width: 180px;
        }
    }
    
   
    .third__wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 78vw;
        margin: auto;
    
    
        .third__wrapper--container {
            display: flex;
            justify-content: space-between;
            width: 90vw;
            background-color: #fff;
            height: 200px;
            border-radius: 18px;
            font-family: Roboto, serif;
            padding-top: 26px;
            margin-bottom: 25px;
    
            .third__wrapper--left {
                margin-left: 2vw;
                p {
                    font-family: Roboto;
                    font-size: 15px;
                    span {
                        font-size: 25px;
                        line-height: 56px;
                    }
                }
                
            }
            .third__wrapper--right {
                margin-right: 2vw;
            }
        }
        
    }
  
} 